export function useAutoScroll(container: Ref<HTMLElement | undefined>) {
  const isAutoScrolling = ref<boolean>(true);
  onUnmounted(() => {
    container.value?.removeEventListener("wheel", onScroll);
    // container.value?.removeEventListener("scroll", onScroll);
    container.value?.removeEventListener("touchmove", onScroll);
  });

  watch(container, (newContainer) => {
    if (newContainer) {
      newContainer.addEventListener("wheel", onScroll);
      // newContainer.addEventListener("scroll", onScroll);
      newContainer.addEventListener("touchmove", onScroll);
    }
  });

  function onScroll() {
    if (!container.value) {
      return;
    }

    if (
      container.value.scrollHeight - container.value.scrollTop - 50 <=
      container.value.clientHeight
    ) {
      isAutoScrolling.value = true;
    } else {
      isAutoScrolling.value = false;
    }
  }

  function scrollToBottom(options?: {
    behavior?: ScrollBehavior;
    force?: boolean;
  }) {
    nextTick(() => {
      if (container.value) {
        if (isAutoScrolling.value || options?.force) {
          container.value.scrollTo({
            top: container.value.scrollHeight,
            behavior: options?.behavior || "smooth",
          });
        }
      }
    });
  }

  function resumeAutoScrolling() {
    isAutoScrolling.value = true;
    scrollToBottom();
  }

  return { isAutoScrolling, scrollToBottom, resumeAutoScrolling };
}
